var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 12 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'title',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'title',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择！',
                    },
                  ],
                },
              ]),expression:"[\n                'type',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请选择！',\n                    },\n                  ],\n                },\n              ]"}]},_vm._l((_vm.partyWorkType),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"来源"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'source',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'source',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"发布时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'publishAt',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'publishAt',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 3 },"wrapper-col":{ span: 18 }}},[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("正文")]),_c('div',{attrs:{"id":"editor"}})])],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"附件","label-col":{ span: 3 },"wrapper-col":{ span: 18 }}},[_c('FileUpload',{attrs:{"multiple":false},on:{"uploaded":_vm.uploaded}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("上传")])],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.fileList,"pagination":false}},[_c('a-table-column',{attrs:{"title":"文件名"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a',{attrs:{"href":text.completePath,"target":"_blank"}},[_vm._v(_vm._s(_vm.$getFileName(text.completePath)))])]}}])}),_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_vm._v("删除")])]}}])})],1)],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.loading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }